import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import Routes from "./routes";
import history from "./utils/history";
import { Position, Toaster, Intent } from "@blueprintjs/core";
import { Helmet } from "react-helmet";

/** Firebase */
import { logEvent } from "firebase/analytics";
import { getAnalytics } from "firebase/analytics";

const title = "Dilkin e Diniz";

const showToast = Toaster.create({
  className: "toaster",
  position: Position.RIGHT_BOTTOM,
});

const App = () => {
  const analytics = getAnalytics();

  useEffect(() => {
    if (!window.navigator.onLine) {
      showToast.show({
        message: "Parece que você está sem internet.",
        intent: Intent.PRIMARY,
      });
    }
    logEvent(analytics, "render_app");
  }, [analytics]);

  return (
    <Router history={history}>
      <Helmet titleTemplate={`%s - ${title}`} />
      <Routes />
    </Router>
  );
};

export default App;
