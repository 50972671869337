import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./styles/main.scss";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

/** Redux */
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

/** Firebase init */
import "./utils/firebaseUtils/firebaseApp";

let persistor = persistStore(store);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
