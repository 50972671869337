import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

/** Redux */
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../redux/User/userSlice";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLogged = useSelector((state) => state.reducer.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) dispatch(signIn(null));
    });
  }, [dispatch]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? <Component {...props} /> : <Redirect to="/manager/signin" />
      }
    />
  );
};

export default PrivateRoute;
