/** Images */
// import DilkinDinizLogo from "../../assets/img/dilkinediniz.svg";

/** Styles */
import "./style.scss";

const Loading = () => (
  <div className="loading">
    {/* <img src={DilkinDinizLogo} alt="logo" /> */}
    <span>Carregando...</span>
  </div>
);

export default Loading;
