import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Loading from "../components/Loading";

import ScrollToTop from "../common/ScrollToTop";

/** Components */
const Home = React.lazy(() => import("../pages/Home"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const Signin = React.lazy(() => import("../pages/Manager/Signin"));
const WorkArea = React.lazy(() => import("../pages/WorkArea"));
const SocialSecurityLaw = React.lazy(() =>
  import("../pages/SocialSecurityLaw")
);

const Blog = React.lazy(() => import("../pages/Blog"));
const Publication = React.lazy(() => import("../pages/Publication"));

const Dashboard = React.lazy(() => import("../pages/Manager/Dashboard"));
const Article = React.lazy(() => import("../pages/Manager/Article"));

export default function Routes() {
  const NotFoundRedirect = () => <Redirect to="/404" />;

  const privateRoutes = [
    {
      path: "/manager/dashboard",
      component: Dashboard,
    },
    {
      path: "/manager/novo-artigo",
      component: Article,
    },
  ];

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <ScrollToTop />

        <Switch>
          {privateRoutes.map((page, index) => (
            <PrivateRoute
              exact
              key={index}
              path={page.path}
              component={page.component}
            />
          ))}
          <Route path="/" component={Home} exact />
          <Route path="/direito-trabalhista" component={WorkArea} exact />
          <Route
            path="/direito-previdenciario"
            component={SocialSecurityLaw}
            exact
          />
          <Route path="/blog" component={Blog} exact />
          <Route path="/blog/:title/:id" component={Publication} exact />
          <Route path="/404" component={NotFound} exact />
          <Route path="/manager/signin" component={Signin} exact />
          <Route component={NotFoundRedirect} />
        </Switch>
      </Suspense>
    </Router>
  );
}
